.uploadContainer{
    display: flex;
    flex-direction: column;
    background: #386880;
    width: 100%;
    height: 100%;
}

.Upload-Top-Section{
    width: 100%;
    height: 70px;
}

.Upload-Header-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 75px;
    width: 100%;
}

.Upload-Content-Section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #386880;
    margin-bottom: 20px;
}

.Upload-Selected-Wrapper{
    width: 90%;
    height: 700px;
    background: black;
    position: relative;
    top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    overflow-y: scroll;
    box-shadow: 2px 2px 10px black;
}

.Upload-Selected-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    border-radius: 10px;
} 
  
.Upload-Selected-Wrapper::-webkit-scrollbar-thumb{
    background-color: #25405f;
    border-radius: 10px;
}
  
.Upload-Selected-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.Upload-Button-Area{
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.Upload-Checkout-Button{
    height: 50px;
    width: 150px;
    border-radius: 10px;
    border: none;
    background: #61b7e2;
    box-shadow: 2px 2px 10px black;
    font-size: 30px;
    transition-duration: .75s;
    cursor: pointer;
}

.Upload-Checkout-Button:hover{
    background: #00aaff;
}

.Upload-Back-Button{
    height: 50px;
    width: 150px;
    border-radius: 10px;
    border: none;
    background: #61b7e2;
    box-shadow: 2px 2px 10px black;
    font-size: 30px;
    margin-right: 40px;
    transition-duration: .75s;
    cursor: pointer;
}

.Upload-Back-Button:hover{
    background: #00aaff;
}

.Upload-Selected-Item{
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition-duration: .75s;
}

.Upload-Selected-Item:hover{
    background: rgb(65, 65, 65) !important;
}

.Upload-Item-Time-Wrapper{
    width: 33.33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
}

.Upload-Item-Time-Text{
    font-size: 30px;
    color: white;
    margin: 0px;
    margin-left: 20px;
    transition-duration: .75s;
}

.Upload-Item-Image-Wrapper{
    width: 33.33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.Upload-Item-Image-Input{
    margin-left: 20px;
    cursor: pointer;
}

.Upload-Item-Image-Label{
    margin-left: 20px;
    cursor: pointer;
    margin-right: 20px;
}

.Upload-Item-URL-Wrapper{
    width: 33.33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
}

.Upload-Item-URL-Input{
    background-color: black;
    border: none;
    height: 30px;
    border-width: 0 0 1px;
    border-radius: 10px;
    color: white;
    font-size: 20px;
    padding: 5px;
    text-align: center;
    outline-width: 0;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    margin-left: 20px;
    cursor: pointer;
}

.Upload-Item-URL-Input:focus{
    border-color: greenyellow;
}

.Upload-Item-URL-Input:hover{
    border-color: green;
}

.Upload-Timeline-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
}

.Upload-Item-Image-Preview{
    height: 40px;
    width: 40px;
    background: #61b7e2;
    border: none;
    color: black;
}

.Upload-Item-Image-Preview:hover{
    background: #00aaff;
}

/* Start here next time - need to figure out resizing for upload page */

@media(max-width: 940px){

    .Upload-Item-Time-Text{
        font-size: 15px;
    }

    .Upload-Item-Image-Label{
        margin-left: 15px;
        margin-left: 0px;
    }

    .Upload-Item-URL-Input{
        font-size: 15px;
        margin-left: 0px;
    }
}

@media(max-width: 700px){

    .Upload-Item-Time-Wrapper{
        width: 33.33%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .Upload-Item-Image-Wrapper{
        width: 33.33%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .Upload-Item-URL-Wrapper{
        width: 33.33%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

@media(max-width: 600px){

    .Upload-Selected-Wrapper{
        width: 95%;
    }

    .Upload-Selected-Item{
        justify-content: space-evenly;
    }

    .Upload-Item-Time-Wrapper{
        width: 90px;
    }

    .Upload-Item-Image-Wrapper{
        width: 90px;
    }

    .Upload-Item-URL-Wrapper{
        width: 90px;
    }

    .Upload-Item-Time-Text{
        width: 100%;
        margin-left: 10px;
        font-size: 15px;
    }

    .Upload-Item-Image-Label{
        width: 100%;
        margin-left: 10px;
        text-align: center;
    }

    .Upload-Item-URL-Input{
        width: 100%;
        margin-left: 10px;
    }
    
}

@media(max-width: 425px){

    .Upload-Selected-Wrapper{
        align-items: flex-start;
        overflow-x: scroll;
    }

    .Upload-Selected-Item{
        width: 400px;
    }
    
}
