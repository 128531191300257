.dropdownContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Dropdown-Menu-Area{
    
}

.menuTitle{
    text-align: center;
}

.menuDropdown{
    height: 400px;
    width: 100px;
    background: black;
    border-radius: 10px;
    overflow-y: scroll;
    box-shadow: 2px 2px 10px black;
}

.menuDropdown::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    border-radius: 10px;
} 
  
.menuDropdown::-webkit-scrollbar-thumb{
    background-color: #25405f;
    border-radius: 10px;
}
  
.menuDropdown::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.Dropdown-Item-Wrapper{
    width: 100%;
    height: 40px;
    background: rgb(0, 0, 0);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition-duration: .75s;
    cursor: pointer;
}

.Dropdown-Item-Wrapper:hover{
    background: rgb(65, 65, 65) !important;
}

.dropdownItem{
    margin: 0;
    color: white;
}

.dropdownItemDisabled{
    margin: 0;
    color: rgb(83, 83, 83);
}

@media(max-width: 360px){
    .menuDropdown{
        width: 75px;
    }

    .menuDropdown::-webkit-scrollbar {
        display: none;
    } 
      
    .menuDropdown::-webkit-scrollbar-thumb{
        display: none;
    }
      
    .menuDropdown::-webkit-scrollbar-track {
        display: none;
    }
    
    .menuTitle{
        font-size: 20px;
    }
}
