.ImageCropComponent-Container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 950px;
    width: 100%;
    background: gray;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

.ImageCropComponent-Cropper-Area{
    height: 85%;
    width: 95%;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}
