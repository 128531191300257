.alertModalContainer{
    display: none;
    position: fixed;
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Sit on top */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5);
}

.alertModalContent{
    background-color: #61b7e2;
    position: relative;
    margin: auto;
    padding: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 10px black;
    /*top: 20%;*/
    border-radius: 10px;
}

/*.modalClose{
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}*/

.alertModalClose:hover,
.alertModal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.AlertModal-Button-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.alertModalText{
    text-align: center;
    font-size: 20px;
}

.alertModalConfirmText{
    width: 120px;
    height: 50px;
    margin-right: 10px;
    border: none;
    background: #d1d1d1;
    transition-duration: .5s;
    border-radius: 10px;
    font-size: 20px;
    box-shadow: 2px 2px 10px black;
}

.alertModalConfirmText:hover{
    background: #ffffff;
}

@media(max-width: 750px){
    .alertModalText{
        font-size: 15px;
    }
}

@media(max-width: 550px){
    .alertModalContent{
        width: 80%;
    }
}