.TestimonialShowcaseBox-Content-Wrapper{
    height: 300px;
    width: 700px;
    background: rgba(238, 238, 238, 0.9);
    box-shadow: 2px 2px 10px black;
    padding: 40px;
    margin-bottom: 40px;
    border-radius: 20px;
}

.TestimonialShowcaseBox-Content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.TestimonialShowcaseBox-Content-Text-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 70%;
    width: 100%;
    margin-bottom: 15px;
}

.TestimonialShowcaseBox-Content-Signature-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 30%;
    width: 100%;
}

.TestimonialShowcaseBox-Content-SignatureText-Wrapper{
    width: 50%;
    height: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.TestimonialShowcaseBox-Content-SignatureImage-Wrapper{
    width: 50%;
    height: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.TestimonialShowcaseBox-Content-Text{
    margin: 0;
    font-size: 25px;
}

.TestimonialShowcaseBox-Content-Signature{
    margin: 0;
    font-size: 20px;
}

.TestimonialShowcaseBox-Signature-Image{
    border-radius: 50%;
    max-height: 130px;
    width: auto;
    height: auto;
}

@media(max-width: 850px){
    .TestimonialShowcaseBox-Content-Wrapper{
        height: 250px;
        width: 500px;
    }

    .TestimonialShowcaseBox-Content-Text{
        font-size: 20px;
    }
    
    .TestimonialShowcaseBox-Content-Signature{
        font-size: 15px;
    }
}

@media(max-width: 650px){
    .TestimonialShowcaseBox-Content-Wrapper{
        height: 200px;
        width: 95%;
    }

    .TestimonialShowcaseBox-Content-Text{
        font-size: 15px;
    }
    
    .TestimonialShowcaseBox-Content-Signature{
        font-size: 12px;
    }

    .TestimonialShowcaseBox-Content-Signature-Wrapper{
        align-items: flex-end;
    }

    .TestimonialShowcaseBox-Signature-Image{
        max-height: 90px;
    }

    .TestimonialShowcaseBox-Content-SignatureImage-Wrapper{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}

@media(max-width: 500px){
    .TestimonialShowcaseBox-Content-Wrapper{
        height: auto;
        width: 95%;
    }
}