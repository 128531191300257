.MMS-Title-Wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 100%;
    cursor: pointer;
}

.MMS-Title{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

@media(max-width: 375px){
    .MMS-Title{
        font-size: 20px;
    }
}