.picktimeContainer{
    display: flex;
    flex-direction: column;
    background: #386880;
    width: 100%;
    height: 100%;
}

.Picktime-Top-Section{
    width: 100%;
    height: 70px;
}

.Picktime-Header-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 75px;
    width: 100%;
}

.Picktime-Content-Section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #386880;
}

.Picktime-Select-Area{
    width: 50%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
}

.Picktime-Dropdown-Wrapper{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Picktime-Dropdown-Hour{
    margin-right: 20px;
}

.Picktime-Dropdown-Minute{
    margin-right: 20px;
}

.Picktime-Dropdown-Second{
    margin-right: 0px;
}

.Picktime-Selected-Wrapper{
    width: 50%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
}

.Picktime-Selected-List{
    width: 400px;
    height: 500px;
    background: black;
    border-radius: 20px;
    overflow-y: scroll;
    box-shadow: 2px 2px 10px black;
}

.Picktime-Selected-List::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    border-radius: 10px;
} 
  
.Picktime-Selected-List::-webkit-scrollbar-thumb{
    background-color: #25405f;
    border-radius: 10px;
}
  
.Picktime-Selected-List::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.Picktime-Selected-Item{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    background: black;
    transition-duration: .75s;
    cursor: pointer;
}

.Picktime-Selected-Item:hover{
    background: #61b7e2 !important;
}

.Picktime-Selected-Item-Text{
    font-size: 30px;
    text-align: center;
    color: white;
    margin: 0px;
    transition-duration: .75s;
}

.Picktime-Selected-Item:hover .Picktime-Selected-Item-Text{
    color: black;
}


.Picktime-AMPM-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Picktime-Selected-Buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Picktime-Button-AM{
    height: 50px;
    width: 50px;
    border-radius: 10px;
    border: none;
    background: #61b7e2;
    box-shadow: 2px 2px 10px black;
    font-size: 20px;
    margin-right: 30px;
    transition-duration: 1s;
    cursor: pointer;
}

.Picktime-Button-AM:hover{
    background: #427d9b;
}

.Picktime-Button-PM{
    height: 50px;
    width: 50px;
    border-radius: 10px;
    border: none;
    background: #2d5468;
    box-shadow: 2px 2px 10px black;
    font-size: 20px;
    transition-duration: 1s;
    cursor: pointer;
}

.Picktime-Button-PM:hover{
    background: #427d9b;
}

.Picktime-Button-Add{
    height: 50px;
    width: 150px;
    border-radius: 10px;
    border: none;
    background: #61b7e2;
    box-shadow: 2px 2px 10px black;
    font-size: 30px;
    margin-top: 20px;
    transition-duration: .75s;
    cursor: pointer;
}

.Picktime-Button-Add:hover{
    background: #00aaff;
}

.Picktime-Button-Remove{
    height: 50px;
    width: 150px;
    border-radius: 10px;
    border: none;
    background: #61b7e2;
    box-shadow: 2px 2px 10px black;
    font-size: 30px;
    margin-top: 20px;
    margin-right: 20px;
    transition-duration: .75s;
    cursor: pointer;
}

.Picktime-Button-Remove:hover{
    background: #00aaff;
}

.Picktime-Button-Continue{
    height: 50px;
    width: 150px;
    border-radius: 10px;
    border: none;
    background: #61b7e2;
    box-shadow: 2px 2px 10px black;
    font-size: 30px;
    margin-top: 20px;
    transition-duration: .75s;
    cursor: pointer;
}

.Picktime-Button-Continue:hover{
    background: #00aaff;
}

.Picktime-Timeline-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
}

@media(max-width: 900px){
    .Picktime-Content-Section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Picktime-Select-Area{
        width: 50%;
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 100px;
        margin-bottom: 100px;
    }

    .Picktime-Dropdown-Wrapper{
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: inline;
        top: 50px;
    }
    

    .Picktime-Selected-Wrapper{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: inline;
        top: 0px;
        margin-bottom: 20px;
    }

}

@media(max-width: 450px){
    .Picktime-Selected-List{
        width: 300px;
        height: 500px;
        background: black;
        border-radius: 20px;
        overflow-y: scroll;
    }
}

@media(max-width: 300px){
    .Picktime-Selected-List{
        width: 250px;
    }
}