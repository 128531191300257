.headerContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: black;
    width: 100%;
    height: 70px;
}

.MMS-Title-Container{
    padding-left: 2.5%;
    height: 100%;
}

.hamburgerContainer{
    padding-right: 2.5%;
}

.About-Link-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.About-Link-Text{
    color: white;
    cursor: pointer;
    transition-duration: .5s;
    margin-right: 20px;
}

.About-Link-Text:hover{
    color: rgb(179, 179, 179);
}

@media(max-width: 375px){
    .About-Link-Text{
        font-size: 20px;
        margin-right: 10px;
    }

}