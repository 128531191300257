.testimonialContainer{
    display: flex;
    flex-direction: column;
    background: #386880;
}

.Testimonial-Top-Section{
    width: 100%;
    height: 70px;
}

.Testimonial-Header-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 75px;
    width: 100%;
}

.Testimonial-Content-Section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #386880;
}

.Testimonial-Content-Header{
    width: 98%;
    height: 500px;
}

.Testimonial-Content-Header-Tile{
    width: 600px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 70px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 2px 2px 10px black;
}

.Testimonial-Content-Header-Title{
    font-size: 50px;
}

.Testimonial-Content-Header-Image{
    width: 100%;
    height: 100%;
    background-repeat: none;
    background-size: cover;
    background-position: center;
}

.Testimonial-Content-Wrapper{
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50px;
}

.Testimonial-Content-1{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 10px;
}

.Testimonial-Content-2{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
    padding: 10px;
}

.Testimonial-Title{
    text-align: center;
    font-size: 30px;
}

.Testimonial-Text{
    text-align: center;
    font-size: 20px;
}

@media(max-width: 660px){
    .Testimonial-Content-Header-Tile{
        width: 300px;
    }
}

@media(max-width: 375px){
    .Testimonial-Content-Header-Title{
        font-size: 40px;
    }
}

@media(max-width: 320px){
    .Testimonial-Content-Header-Tile{
        width: 90%;
    }
}
