.clockContainer{
    height: 15%;
    width: 100%;
    background: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.clockWrapper{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.clockText{
    font-size: 70px;
    font-family: "digital-clock-font";
}

@media(max-width: 425px){
    .clockText{
        font-size: 50px;
        font-family: "digital-clock-font";
    }
}