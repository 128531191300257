.hamburgerWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60px;
    height: 35px;
    cursor: pointer;
}

.hamburger-bar-1{
    width: 100%;
    height: 5px;
    background: white;
    border-radius: 5px;
    transition-duration: 1s;
}

.hamburger-bar-2{
    width: 100%;
    height: 5px;
    background: white;
    border-radius: 5px;
    transition-duration: 1s;
}

.hamburger-bar-3{
    width: 100%;
    height: 5px;
    background: white;
    border-radius: 5px;
    transition-duration: 1s;
}

