#success-area{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
}

#success-message{
    font-size: 5rem;
    color: black;
}

#home-button-success{
    border-radius: 20px;
    font-size: 20px;
    padding: 14px 80px;
    cursor: pointer;
    color: white;
    background-color: #00aaff;
    font-size: 1.5rem;
    font-family: 'Roboto';
    font-weight: 100;
    border: none;
    box-shadow: 2px 2px 20px black;
    margin-top: 30px;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
}

#home-button-success:hover{
    color: black;
    background-color: #61b7e2;
}

@media(max-width: 1300px){
    #success-message{
        font-size: 4rem;
    }
}

@media(max-width: 1070px){
    #success-message{
        font-size: 3rem;
    }
    
    #home-button-success{
        padding: 10px 70px;
    }
}

@media(max-width: 820px){
    #success-message{
        font-size: 2rem;
    }

    #home-button-success{
        padding: 8px 65px;
    }
}

@media(max-width: 1200px){
    
}