.faqContainer{
    display: flex;
    flex-direction: column;
    background: #386880;
}

.FAQ-Top-Section{
    width: 100%;
    height: 70px;
}

.FAQ-Header-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 75px;
    width: 100%;
}

.FAQ-Content-Section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #386880;
}

.FAQ-Content-Header{
    width: 98%;
    height: 500px;
}

.FAQ-Content-Header-Tile{
    width: 600px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 70px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 2px 2px 10px black;
}

.FAQ-Content-Header-Title{
    font-size: 50px;
}

.FAQ-Content-Header-Image{
    width: 100%;
    height: 100%;
    background-repeat: none;
    background-size: cover;
    background-position: center;
}

.FAQ-Content-Wrapper{
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50px;
}

.FAQ-Content-1{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 10px;
    border-radius: 20px;
}

.FAQ-Content-2{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
    padding: 10px;
    border-radius: 20px;
    
}

.FAQ-Title{
    text-align: center;
    font-size: 30px;
}

.FAQ-Text{
    text-align: center;
    font-size: 20px;
}

@media(max-width: 660px){
    .FAQ-Content-Header-Tile{
        width: 300px;
    }
}

@media(max-width: 425px){
    .FAQ-Content-Wrapper{
        width: 85%;
    }
}

@media(max-width: 320px){
    .FAQ-Content-Header-Tile{
        width: 90%;
    }
}
