.Image-Text-Tile-Container{
    width: 30%;
    height: 100%;
    background: white;
    position: relative;
    transition-duration: 1s;
    cursor: pointer;
}

.Image-Text-Tile-Container:hover{
    background: rgb(204, 204, 204);
}

.Image-Text-Tile-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Image-Text-Tile-Banner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 70%;
    left: -10px;
    background: #386880;
    width: 350px;
    height: 50px;
    padding: 5px;
}

.Image-Text-Tile-Banner-Text{
    color: white;
    font-size: 30px;
}

.Image-Text-Tile-Image{
    grid-area: image;
    height: 720px;
    width: 98%;
    /*background-image: url(../Assets/midmi.jpg);*/
    background-size: cover;
    background-position: center;
    margin-top: 5px;
}

.Image-Text-Tile-Text{
    grid-area: text;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 170px;
}

.Image-Text-Tile-Tile-Text{
    color: black;
    font-size: 25px;
}

@media(max-width: 1255px){
    .Image-Text-Tile-Container{
        width: 85%;
        height: 1000px;
        margin-top: 20px;
    }
}

@media(max-width: 425px){
    .Image-Text-Tile-Container{
        width: 85%;
        height: 700px;
        margin-top: 20px;
    }
}

@media(max-width: 425px){
    .Image-Text-Tile-Banner{
        width: 200px;
        top: 75%;
    }
    
    .Image-Text-Tile-Banner-Text{
        color: white;
        font-size: 20px;
    }
}

@media(max-width: 320px){
    .Image-Text-Tile-Tile-Text{
        color: black;
        font-size: 20px;
    }

}
