.slideshowContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height:100%;
    cursor: pointer;
}

.slideshowImage{
    height: 85%;
    width: 95%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}